import React from 'react';
import MailChimpForm from 'components/MailChimpForm/MailChimpForm';
import pivot from 'assets/images/pivot.svg';
import Container from 'components/Container/Container';
import videoImage from 'assets/images/dark-theme.svg';
import './Pivot.scss';

const Pivot = () => (
  <div className="Pivot">
    <Container>
      <div className="Pivot-inner">
        <div className="Pivot-content wow fadeInLeft">
          <h2>Don’t have testers?<br />We’ve got you covered.</h2>
          <p>
            Tap into our community of 50,000+ testers. (Starting at $2.5/session.) And you can always
            bring your own participants.
          </p>
          <MailChimpForm themeColor="blue" />
        </div>
      </div>
    </Container>
    <div className="Pivot-image wow fadeInRight">
      {/* <img alt="Pivot Image" src={pivot} /> */}
      <img src={videoImage} alt="Video Image" draggable="false"/>
    </div>
  </div>
);

export default Pivot;
