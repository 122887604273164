import React from 'react';
import PropTypes from 'prop-types';

const OfferBox = ({ icon, title, desc }) => {
  return (
    <div className="OfferBox">
      <div><img src={icon} /></div>
      <div>
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </div>
  );
};

OfferBox.propTypes = {
  icon: PropTypes.node.isRequired,
};

export default OfferBox;
