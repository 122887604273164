import React from 'react';
import PropTypes from 'prop-types';
import MailChimpForm from 'components/MailChimpForm/MailChimpForm';
import Container from 'components/Container/Container';
import './Main.scss';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  handleChange = event => {
    return this.setState({ email: event.target.value });
  };

  render() {
    const { email } = this.state;
    return (
      <Container>
        <h1 className="wow fadeInUp" data-wow-duration=".6s">
          <span className="Main-text-gradient">Interactive</span> proptype <br/>meets <span className="Main-text-gradient">actionable</span> insights
        </h1>
        <div className="Main-footer">
          <div className="wow fadeInDown" data-wow-duration=".6s">
            <h4>ZYPSY</h4>
            <p className="Main-text">
              Transform your prototype into actionable insights, getting key feedback from the
              people you are designing for.
            </p>
          </div>
          <div className="wow fadeInRight">
            <h4>REQUEST TRIAL</h4>
            <MailChimpForm />
          </div>
        </div>
      </Container>
    );
  }
}

export default Main;
