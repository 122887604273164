import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Section.scss';

const Section = ({ bgColor, padding, children }) => (
  <div style={{ padding: `${padding} 0` }} className={cx('Section', `Section--${bgColor}`)}>
    {children}
  </div>
);

Section.propTypes = {
  bgColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Section;
