import { Content, Footer, Header, IntercomComponent, Video } from 'components/index';
import Main from 'components/Main/Main';
import Offers from 'components/Offers/Offers';
import Pivot from 'components/Pivot/Pivot';
import Section from 'components/Section/Section';
import config from 'constants/config';
import React, { Component } from 'react';
import 'styles/global.scss';
import MetaTag from './MetaTag';

class Index extends Component {
  componentDidMount() {
    const WOW = require('wowjs').WOW;
    new WOW().init();
  }
  render() {
    return (
      <div>
        <MetaTag />
        <Section bgColor="darkgray">
          <Header />
          <Main />
        </Section>
        <Section bgColor="black">
          <Video />
        </Section>
        <Section bgColor="darkgrey">
          <Content />
        </Section>
        <Section bgColor="darkgray">
          <Offers />
        </Section>
        <Section bgColor="darkgray">
          <Pivot />
        </Section>
        <Section bgColor="darkgrey">
          <Footer />
        <IntercomComponent />
        </Section>
        {/* <a id="intercomLink" href={`mailto:${config.intercomToken}@intercom-mail.com`}>
          Support
    </a> */}
      </div>
    );
  }
}
export default Index;
