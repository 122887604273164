const SEO = {
  metadataId: '2oj1EKvxeQ4g2g0wsys6mI',
  title: 'Zypsy - Interactive prototypes meet actionable insights',
  author: 'Zypsy, Inc',
  description:
    'Transform your prototype into actionable insights, getting key feedback from the people you are designing for.',
  type: 'Website',
  twitter_username: '@zypsycom',
  app_id: '1709734352602424',
  url: 'zypsy.design',
  image_url: 'https://zypsy.design/images/og.jpg',
  appicon: 'https://zypsy.design/images/favicon/apple-touch-icon.png',
  favicon32: 'https://zypsy.design/images/favicon/favicon-32x32.png',
  favicon16: 'https://zypsy.design/images/favicon/favicon-16x16.png',
  webmanifest: 'https://zypsy.design/images/favicon/site.webmanifest',
  mask_icon: 'https://zypsy.design/images/favicon/safari-pinned-tab.svg',
  msapplication_tilecolor: '#0e0e0e',
  theme_color: '#0e0e0e',
};

export default SEO;
