import React from 'react';
import Helmet from 'react-helmet';
import SEO from '../constants/metaTags';

const MetaTags = () => (
  <Helmet>
    {/* General tags */}
    <title>{SEO.title}</title>
    <meta name="description" content={SEO.description} />
    <meta name="image" content={SEO.image_url} />
    <meta name="web_author" content={SEO.author} />
    <link rel="canonical" href={SEO.url} />
    <meta property="og:type" content={SEO.type} />
    <link rel="icon" href={SEO.favicon16} type="image/gif" sizes="16x16" />
    <link rel="icon" href={SEO.favicon32} type="image/gif" sizes="32x32" />
    <meta name="theme-color" content={SEO.theme_color} />
    <link rel="manifest" href={SEO.webmanifest} />
    <link rel="mask-icon" href={SEO.mask_icon} />
    <meta name="msapplication-TileColor" content={SEO.msapplication_tilecolor} />
    {/* OpenGraph tags */}
    <meta property="og:url" content={SEO.url} />
    <meta property="og:title" content={SEO.title} />
    <meta property="og:description" content={SEO.description} />
    <meta property="og:image" content={SEO.image_url} />
    <meta property="fb:app_id" content={SEO.app_id} />
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
    />
    {/* Twitter Card tags */}
    <meta name="twitter:card" content={SEO.description} />
    <meta name="twitter:creator" content={SEO.twitter_username} />
    <meta name="twitter:title" content={SEO.title} />
    <meta name="twitter:description" content={SEO.description} />
    <meta name="twitter:image" content={SEO.image_url} />
    <link rel="stylesheet" href="assets/fonts" />
    <link rel="stylesheet" href="styles/global.scss" />
    <script src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.js" />
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-136754542-1" />
    <script>
      window.dataLayer = window.dataLayer || []; gtag = () => dataLayer.push(arguments); gtag('js',
      new Date()); gtag('config', 'UA-136754542-1');
    </script>
  </Helmet>
);

export default MetaTags;