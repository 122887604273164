import React from 'react';
import PropTypes from 'prop-types';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import SubscribeForm from 'components/SubscribeForm/SubscribeForm';
import config from 'constants/config';

const MailChimpForm = ({ themeColor }) => (
  <MailchimpSubscribe
    url={config.mailchimpToken}
    render={({ subscribe, status, message }) => (
      <SubscribeForm
        status={status}
        message={message}
        onValidated={formData => subscribe(formData)}
        themeColor={themeColor}
      />
    )}
  />
);

MailChimpForm.propTypes = {
  themeColor: PropTypes.string,
};

MailChimpForm.defaultProps = {
  themeColor: 'default',
};

export default MailChimpForm;
