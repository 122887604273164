import React from 'react';
import facebook from 'assets/images/logo_facebook.svg';
import google from 'assets/images/logo_google.svg';
import amazon from 'assets/images/logo_amazon.svg';
import ideo from 'assets/images/logo_ideo.svg';
import lyft from 'assets/images/logo_lyft.svg';
//import videoImage from 'assets/images/dark-theme.svg';
import Container from 'components/Container/Container';
import './Video.scss';

import Vimeo from '@u-wave/react-vimeo';

const Video = () => {
  return (
    <div className="Video wow fadeInUp" data-wow-duration="1s">
      <Container>
        <div className="Video-image">
          <iframe src="https://player.vimeo.com/video/326256226?autoplay=1&loop=1&autopause=0&background=1" frameBorder="0"></iframe>
        </div>
        <div className="overly Container-width" />
      </Container>
      <Container>
        <div className="Video-logos-box wow fadeIn">
          <p>Used by designers at the world’s best companies</p>
          <div className="Video-logos">
            <div className="Video-logo-image">
              <img src={amazon} alt="amazon" draggable="false"/>
            </div>
            <div className="Video-logo-image">
              <img src={facebook} alt="facebook" draggable="false"/>
            </div>
            <div className="Video-logo-image">
              <img src={google} alt="google" draggable="false"/>
            </div>
            <div className="Video-logo-image">
              <img src={ideo} alt="ideo" draggable="false"/>
            </div>
            <div className="Video-logo-image">
              <img src={lyft} alt="lyft" draggable="false"/>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Video;
