import React from 'react';
import { offerBox } from 'constants/offerBox.js';
import OfferBox from 'components/Offers/OfferBox';
import Container from 'components/Container/Container';
import './Offers.scss';

const Offers = () => {
  return (
    <div className="Offer">
    <Container>
      <div className="Offers-text">
        <h2>What can you do with Zypsy?</h2>
        <p>
          From your first design mockup to your launched product, and everything in between, 
          Zypsy has your user testing needs covered.
        </p>
      </div>
      <div className="OfferGrid">
        {offerBox.map(offer => (
          <OfferBox key={offer.title} icon={offer.icon} title={offer.title} desc={offer.desc} />
        ))}
      </div>
    </Container>
    </div>
  );
};

export default Offers;