import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Container.scss';

const Container = ({ children, bgColor }) => (
  <div className={cx('container', `container--${bgColor}`)}>{children}</div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
