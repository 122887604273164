import React from 'react';
import logo from 'assets/images/logo.svg';
import Container from '../Container/Container';
import twitter from 'assets/images/icons/social/twitter.svg'
import dribbble from 'assets/images/icons/social/dribble.svg'
import Facebook from 'assets/images/icons/social/Facebook.svg'
import Instagram from 'assets/images/icons/social/Instagram.svg'
import './Footer.scss';
const Footer = () => (
    <Container>
            <div className='footer'>
                <div className="logo-box">
                    <div>100 Broadway  St. San Francisco, CA 94107</div>
                    <img ult="zypsy" src={logo}/>
                </div>
                <div className="address-box">
                    <ul>
                        <li><a href="https://twitter.com/zypsycom/" target="_blank"><img style={{fill: 'white'}} ult="Twitter" src={twitter} /></a></li>
                        <li><a href="https://dribbble.com/zypsy/" target="_blank"><img style={{fill: 'white'}} ult="Dribble" src={dribbble} /></a></li>
                        <li><a href="https://www.facebook.com/zypsycom/" target="_blank"><img ult="Facebook"  src={Facebook} /></a></li>
                        <li><a href="https://instagram.com/zypsycom/" target="_blank"><img ult="Instagram"  src={Instagram} /></a></li>
                    </ul>
                    <div>Copyright © 2019 Zypsy, Inc All rights reserved.</div>
                </div>
            </div>
    </Container>
)

export default Footer;