import React from 'react';
import createtest from 'assets/images/create-test.png';
import audience from 'assets/images/audience.png';
import analysis from 'assets/images/analysis.png';
import figma from 'assets/images/Icons/tool/figma.svg';
import framer from 'assets/images/Icons/tool/framer.svg';
import invision from 'assets/images/Icons/tool/invision.svg';
import marvel from 'assets/images/Icons/tool/marvel.svg';
import sketch from 'assets/images/Icons/tool/sketch.svg';
import xd from 'assets/images/Icons/tool/xd.svg';
import check from 'assets/images/Check.svg';
import './Content.scss';

const contentTexts = [
  'Import your prototype. Just copy and paste the URL',
  'Create a task and set the expected path to success',
  'Start getting valuable insights',
];

const Content = () => {
  return (
    <div className="Content">
      <div className="Content-wrapper container">
        <div className="img-box wow fadeInLeft">
          <img src={createtest} draggable="false" />
        </div>
        <div className="text-box wow fadeInRight">
          <h2>
            Import prototype <br />
            and create tests
          </h2>
          <p className="Content-description">
            Build tests from all your favorite prototyping tools. 
            How it works in three easy steps:
          </p>
          <div className="Content-lists-wrapper">
            {contentTexts.map(text => (
              <div key={text} className="Content-lists">
                <img src={check} alt="icon" draggable="false" />
                <p>{text}</p>
              </div>
            ))}
          </div>
          <div className="Content-icons">
            <img alt="invision" src={invision} draggable="false"/>
            <img alt="figma" src={figma} draggable="false"/>
            <img alt="framer" src={framer} draggable="false"/>
            <img alt="marvel" src={marvel} draggable="false"/>
            <img alt="sketch" src={sketch} draggable="false"/>
            <img alt="xd" src={xd} draggable="false"/>
          </div>
        </div>
      </div>

      <div className="Content-wrapper container reverse">
        <div className="text-box wow fadeInLeft">
          <h2>
            Invite relevant users <br />
            or recruit them
          </h2>
          <p>
            It’s easy to connect with the people you’re designing for. 
            Just bring your own users through our SDK or hire them from our community of 50,000+ testers.
          </p>
        </div>
        <div className="img-box wow fadeInRight">
          <img alt="audience" src={audience} draggable="false" />
        </div>
      </div>

      <div className="Content-wrapper container">
        <div className="img-box wow fadeInLeft">
          <img alt="analysis" src={analysis} draggable="false" />
        </div>
        <div className="text-box wow fadeInRight">
          <h2>
            Get automated UX reports <br />
            for easier collaboration
          </h2>
          <p>
            Convert your user feedback data into actionable insights so that everyone on your team
            can learn, weigh in and make the next prototype better than ever.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content;
