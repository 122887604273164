import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import arrow from 'assets/images/icons/arrow.svg';
import './SubscribeForm.scss';

const SubscribeForm = ({ status, message, onValidated, themeColor }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div>
      <div className={cx('SubscribeForm', `SubscribeForm-theme--${themeColor}`)}>
        <input
          className="SubscibeForm-input"
          ref={node => (email = node)}
          type="email"
          placeholder="Enter your email"
        />
        <button className="SubscibeForm-button" onClick={submit}>
          <span className="SubscribeForm-button-text">REQUEST</span><img src={arrow} alt="Arrow Icon" />
        </button>
      </div>
      <div className="SubscribeForm-msg">
        {status === 'sending' && <div className="SubscribeForm-msg--sending">sending...</div>}
        {status === 'error' && (
          <div className="SubscribeForm-msg--error" dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <div
            className="SubscribeForm-msg--success"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    </div>
  );
};

SubscribeForm.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onValidated: PropTypes.func.isRequired,
  themeColor: PropTypes.string,
};

SubscribeForm.defaultProps = {
  status: null,
  message: undefined,
  themeColor: 'default',
};

export default SubscribeForm;
