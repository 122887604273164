import React from 'react';
import Container from '../Container/Container';
import logo from 'assets/images/logo.svg';
import './Header.scss';

const Header = () => (
  <Container>
    <div className="Header">
      <img alt="zypsy logo" src={logo} />
      <div className="cta"><a href="mailto:info@zypsy.com">Contact</a></div>
    </div>
  </Container>
);

export default Header;
