import build from 'assets/images/icons/build.svg';
import testing from 'assets/images/icons/testing.svg';
import spilite from 'assets/images/icons/spilite.svg';
import performance from 'assets/images/icons/performance.svg';
import feature from 'assets/images/icons/feature.svg';
import validation from 'assets/images/icons/validation.svg';

export const offerBox = [
  {
    icon: build,
    title: 'Build a new product',
    desc: "Don't spend time building out the wrong idea. Get the right insights ahead of time.",
  },
  {
    icon: testing,
    title: 'Usability testing',
    desc: 'Find out exactly what your users are seeing, thinking, saying and clicking on.',
  },
  {
    icon: spilite,
    title: 'A/B Test multiple designs',
    desc: 'Optimize your app experience through visual experimentation to find the best fit.',
  },
  {
    icon: performance,
    title: 'Compare copy performance',
    desc: 'Test and experiment with your copy early on to find the right words for every screen.',
  },
  {
    icon: feature,
    title: 'Build new features',
    desc: 'Validate your ideas before developing features no one will use.',
  },
  {
    icon: validation,
    title: 'Validation of micro-interactions',
    desc: "Enhance your user's experience every step of the way. We help you fine-tune every detail.",
  },
];
